import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Datepicker from "react-tailwindcss-datepicker";
import {UserCircleIcon} from '@heroicons/react/24/solid';
import {AxiosInstance} from "../services/http";
import {Loading} from "../components/loading";
import {ManualSubscription} from "../components/manual.subscription";
import {StripeSubscription} from "../components/stripe.subscription";
import {TrashIcon} from "@heroicons/react/20/solid";
import {NotFound} from "./not.found";
import { useToast } from "../contexts/ToastContext";

export const Customer = () => {
    let {customer_id} = useParams();
    const [fetching, setFetching] = useState(true);
    const [isUpdatingCustomer, setIsUpdatingCustomer] = useState(false);
    const [isRestoringCustomer, setIsRestoringCustomer] = useState(false);
    const [isDeletingCustomer, setIsDeletingCustomer] = useState(false);
    const [customer, setCustomer] = useState(undefined);
    const toast = useToast();
    const getCustomer = (customer_id) => {
        AxiosInstance('GET', '/api/v1/customers/' + customer_id)
            .then(res => {
                if (res.status === 200){
                    const response = res.data.resulting_data;
                    const result = {
                        ...response,
                        verifiedAt: response.verifiedAt ? { startDate: response.verifiedAt.split(' ')[0], endDate: response.verifiedAt.split(' ')[0]} : { startDate: null, endDate: null}
                    };
                    setCustomer(result);
                }else {
                    setCustomer(null);
                    toast('error', 'Error!', 'Error fetching customer!');
                }
                setFetching(false);
            })
    }
    useEffect(() => {
        getCustomer(customer_id);
    }, [customer_id]);

    const updateCustomer = (restore) => {
        if (restore) {
            setIsUpdatingCustomer(true);
        }else {
            setIsRestoringCustomer(true);
        }

        AxiosInstance('PUT', '/api/v1/customers/' + customer_id,{},{
            name: customer.name,
            stripe_id: customer.stripe_id,
            email: customer.email,
            is_enterprise: customer.is_enterprise,
            verifiedAt: customer.verifiedAt.startDate ?  customer.verifiedAt.startDate + ' 00:00:00' : undefined,
            restore
        })
        .then(res => {
            if (res.status === 200){
                const response = res.data.resulting_data;
                const result = {
                    ...response,
                    verifiedAt: response.verifiedAt ? { startDate: response.verifiedAt.split(' ')[0], endDate: response.verifiedAt.split(' ')[0]} : { startDate: null, endDate: null}
                };
                setCustomer(result);
                toast('success', 'Success!', 'Customer updated successfully!');
            }else{
                toast('error', 'Error!', 'Error updating customer!');
            }
            setIsUpdatingCustomer(false);
            setIsRestoringCustomer(false);
        });
    }

    const deleteCustomer = (customer_id) =>  {
        setIsDeletingCustomer(true);
        AxiosInstance('DELETE',`/api/v1/customers/${customer_id}`)
            .then(res => {
                setIsDeletingCustomer(false);
                if (res.status === 200){
                    const response = res.data.resulting_data;
                    const result = {
                        ...response,
                        verifiedAt: response.verifiedAt ? { startDate: response.verifiedAt.split(' ')[0], endDate: response.verifiedAt.split(' ')[0]} : { startDate: null, endDate: null}
                    };
                    setCustomer(result);
                    toast('success', 'Success!', 'Customer deleted successfully!');
                }else{
                    toast('error', 'Error!', 'Error deleting customer!');
                }
            });
    }

    return (
        fetching ? <Loading/> : (
            customer ? (
                <div className="space-y-10 divide-y divide-gray-900/10">
                    <div className="grid grid-cols-6 gap-x-8 gap-y-8 pt-10 md:grid-cols-6 h-1/4 pb-4">
                        <div className="sm:col-span-3">
                            <div className="px-4 sm:px-0  md:col-span-3">
                                <h2 className="text-base font-semibold leading-7 text-gray-900">
                                    Personal Information :
                                    {customer.is_deleted && <span
                                        className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                                    This customer is DELETED before!
                                  </span>}
                                </h2>
                            </div>

                            <form className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-3 mt-2  h-full">
                                <div className="px-4 py-6 sm:p-8">
                                    <div className="grid grid-cols-6 gap-x-6 gap-y-8 sm:grid-cols-12">
                                        <div className="sm:col-span-12">
                                            <div className="col-span-full">
                                                <div className="mt-2 flex items-center gap-x-3">
                                                    {customer.avatar ? <img className="h-20 w-20 rounded-full"
                                                                            src={customer.avatar} alt=""/> :
                                                        <UserCircleIcon className="h-20 w-20 text-gray-300"
                                                                        aria-hidden="true"/>}
                                                </div>
                                            </div>
                                            <div></div>
                                        </div>
                                        <div className="sm:col-span-6">
                                            <label htmlFor="first-name"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                Full Name
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    type="text"
                                                    name="name"
                                                    id="name"
                                                    value={customer.name}
                                                    onChange={(e) => setCustomer({...customer, name: e.target.value})}
                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                />
                                            </div>
                                        </div>

                                        <div className="sm:col-span-6">
                                            <label htmlFor="verified-at"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                Verified At
                                            </label>
                                            <div className="mt-2">
                                                <Datepicker
                                                    useRange={false}
                                                    asSingle={true}
                                                    primaryColor={"indigo"}
                                                    value={customer.verifiedAt}
                                                    inputClassName="w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    onChange={(v) => {
                                                        setCustomer({...customer, verifiedAt: v});
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="sm:col-span-6">
                                            <label htmlFor="email"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                Email address
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    id="email"
                                                    name="email"
                                                    type="email"
                                                    value={customer.email}
                                                    onChange={(e) => setCustomer({...customer, email: e.target.value})}
                                                    autoComplete="email"
                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                />
                                            </div>
                                        </div>

                                        <div className="sm:col-span-6">
                                            <label htmlFor="email"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                Customer ID in Stripe
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    id="stripe_id"
                                                    name="stripe_id"
                                                    type="text"
                                                    value={customer.stripe_id}
                                                    onChange={(e) => setCustomer({
                                                        ...customer,
                                                        stripe_id: e.target.value
                                                    })}
                                                    autoComplete="email"
                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                />
                                            </div>
                                        </div>
                                        <div className="sm:col-span-1">
                                            <label htmlFor="is_entrprise"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                enterprise?
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    id="is_entrprise"
                                                    name="is_entrprise"
                                                    type="checkbox"
                                                    checked={customer.is_enterprise}
                                                    onChange={(e) => setCustomer({
                                                        ...customer,
                                                        is_enterprise: !customer.is_enterprise
                                                    })}
                                                    className="block w-full rounded-md border-0 py-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                                    {
                                        !customer.is_deleted && <button
                                            disabled={isDeletingCustomer}
                                            onClick={() => deleteCustomer(customer_id)}
                                            type="button"
                                            className="inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        >
                                            {isDeletingCustomer ? 'Please wait' :
                                                <TrashIcon className="-ml-0.5 h-5 w-5" aria-hidden="true"/>}
                                        </button>
                                    }
                                    {
                                        customer.is_deleted && <button
                                            disabled={isRestoringCustomer}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                updateCustomer(false);
                                            }}
                                            type="submit"
                                            className="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                                        >
                                            {isRestoringCustomer ? 'Please Wait' : 'Restore'}
                                        </button>
                                    }

                                    <button
                                        disabled={isUpdatingCustomer}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            updateCustomer(true);
                                        }}
                                        type="submit"
                                        className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        {isUpdatingCustomer ? 'Please Wait' : 'Update'}
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div className="sm:col-span-3">
                            <h2 className="text-base font-semibold leading-7 text-gray-900">
                                Last 10 Logs
                            </h2>
                            <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl rounded-lg px-4 sm:px-6 lg:px-8 h-full">
                                <div className="mt-4">
                                    <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                                        <div className="inline-block min-w-full py-2 align-middle">
                                            <table className="min-w-full border-separate border-spacing-0">
                                                <thead>
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                                                    >
                                                        Name
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                                                    >
                                                        IP Address
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                                                    >
                                                        City
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                                                    >
                                                        Country
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-3 pr-4 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
                                                    >
                                                        <span className="sr-only">Edit</span>
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {customer.last_10_events.map((log, index) => (
                                                    <tr key={index}>
                                                        <td className='whitespace-nowrap py-2 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8'>
                                                            {log.name}
                                                        </td>
                                                        <td className='whitespace-nowrap py-2 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8'>
                                                            {log.ip_address}
                                                        </td>
                                                        <td className='whitespace-nowrap py-2 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8'>
                                                            {log.extra_data.city}
                                                        </td>
                                                        <td className='whitespace-nowrap py-2 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8'>
                                                            {log.extra_data.country}
                                                        </td>
                                                        <td className='whitespace-nowrap py-2 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8'>
                                                            {log.createdAt.replace('T',' ').split('.')[0]}
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="md:grid md:grid-cols-12 sm:grid sm:grid-cols-12 mt-2 pt-4">
                        <div className="md:col-span-6 sm:col-span-6">
                            <h2 className="text-base font-semibold leading-7 text-gray-900">
                                Manual Subscriptions :
                            </h2>
                            <div className="pt-2 pb-2 pr-1">
                                {
                                    customer.manual_subscriptions.map((subscription, index) => {
                                        return <ManualSubscription re_fetch_customer={() => getCustomer(customer.id)}
                                                                   subscription={subscription} user_id={customer.id}
                                                                   key={index}/>
                                    })
                                }
                                <ManualSubscription re_fetch_customer={() => getCustomer(customer.id)}
                                                    subscription={undefined}
                                                    user_id={customer.id}/>
                            </div>
                        </div>
                        <div className="md:col-span-6 sm:col-span-6">
                            <h2 className="text-base font-semibold leading-7 text-gray-900">
                                Stripe Subscriptions :
                            </h2>
                            <div className="pt-2 pb-2 pl-1">
                                {
                                    customer.stripe_subscriptions.length > 0 ?
                                        (
                                            customer.stripe_subscriptions.map((subscription, index) => {
                                                return <StripeSubscription subscription={subscription} key={index}/>
                                            })
                                        )
                                        :
                                        <div className="text-sm text-center flex justify-center align-baseline pt-10">No
                                            stripe subscriptions!</div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            ) : <NotFound/>
        )
    )
}
