export const is_free_list = [
    {
        id: undefined,
        name: 'Select Is Free'
    },
    {
        id: 1,
        name: 'Free'
    },
    {
        id: 0,
        name: 'Pro'
    }
];

export const getIsFreeByID = (id) => {
    return is_free_list.filter(item => item.id === id)[0] ?? {id: undefined, name: 'Select Is Free'}
}
