export const statuses = [
    {id: 'all', name: 'Select Status'},
    {id: 'waiting', name: 'Waiting'},
    {id: 'accepted', name: 'Accepted'},
    {id: 'declined', name: 'Declined'},
    {id: 'done', name: 'Done'}
];
export const getStatusByName = (name) => {
    return statuses.filter((status) => status.id === name)[0];
}
