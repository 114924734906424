import React from "react";
import {Statistics} from "../components/statistics";

export const Dashboard = () => {
    return (
        <React.Fragment>
            <Statistics />
        </React.Fragment>
    )
}
