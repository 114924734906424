import { configureStore } from '@reduxjs/toolkit'
import loginSlice from "./reducers/login";

export default configureStore({
    reducer: {
        login: loginSlice
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    })
})
