import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {AxiosInstance} from "../../services/http";
import {AxiosError} from "axios";

export const login = createAsyncThunk('auth/login',
    async (credentials, thunkAPI) => {
        return  AxiosInstance('post', '/api/v1/auth/login', {},credentials)
            .then(response => {
                return response;

            })
            .catch(e => {
                return e;
            });
    });

export const loginSlice = createSlice({
    name: 'login',
    initialState: {
        loading: false,
        error: null,
        result: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(login.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(login.fulfilled, (state, action) => {
            state.loading = false;
            const res = action.payload;
            if (res instanceof AxiosError){
                if (res.response) {
                    if (res.response.data.message){
                        state.error = res.response.data.message;
                    }else if (res.response.data.error) {
                        state.error = res.response.data.error;
                    }else {
                        state.error = 'Something went wrong!';
                    }
                }else {
                    state.error = res.message;
                }
            }else {
                localStorage.setItem('auth_token', res.data.resulting_data.accessToken)
                state.result = res.data;
            }
        });
        builder.addCase(login.rejected, (state, action) => {
            state.loading = false;
            state.error = 'Ooops! Something went wrong!';
        })
    }
});

export default loginSlice.reducer
