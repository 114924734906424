export const getPlans = () => {
    let list = [{
        id: 'all',
        name: 'Select Plan',
        tiers_mode: 'year'
    }];
    const plans = JSON.parse(process.env.REACT_APP_PLANS_LIST);
    return [...list, ...plans];
}

export const getPlanByID = (id) => {
    const plans = getPlans();
    return plans.filter(plan => plan.id === id)[0];
}

export const getPlanTierMode = (plan) => {
    if (plan.plan === 'Annually'){
        return 'year';
    }
    return 'month';
}
