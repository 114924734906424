export const styles = {
    icons: [
        {
            "id": undefined,
            "name": "Select Style"
        },
        {
            "id": 1,
            "name": "Bold"
        },
        {
            "id": 3,
            "name": "Bulk"
        },
        {
            "id": 4,
            "name": "Light"
        },
        {
            "id": 5,
            "name": "Light-Outline"
        },
        {
            "id": 6,
            "name": "Two-Tone"
        },
        {
            "id": 9,
            "name": "Broken"
        }
    ],
    animations: [
        {
            "id": undefined,
            "name": "Select Style"
        },
        {
            "id": 1,
            "name": "Light"
        },
        {
            "id": 2,
            "name": "Two-Tone"
        }
    ],
    threeds: [
        {
            "id": undefined,
            "name": "Select Style"
        },
        {
            "id": 1,
            "name": "Clay"
        },
        {
            "id": 2,
            "name": "Dark"
        },
        {
            "id": 3,
            "name": "Family"
        },
        {
            "id": 4,
            "name": "Real"
        }
    ]
};

export const getStyleByID = (resource, id) => {
    return styles[resource].filter((item) => item.id === id)[0];
}
