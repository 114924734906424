import {
    HomeIcon,
    UsersIcon,
    CubeTransparentIcon,
    QuestionMarkCircleIcon,
    RocketLaunchIcon,
    SwatchIcon,
    CubeIcon,
    PaintBrushIcon,
    TagIcon,
    BellIcon,
    EnvelopeIcon
} from '@heroicons/react/24/outline';
import {Dashboard} from "./pages/dashboard";
import {Customers} from "./pages/customers";
import {IconRequests} from "./pages/icon.requests";
import {Categories} from "./pages/categories";
import {Styles} from "./pages/styles";
import {Types} from "./pages/types";
import {Resources} from "./pages/resources";
import React from "react";
import {Notifications} from "./pages/notifications";
import {MarketingEmails} from "./pages/marketing.emails";

export const routes = [
    {
        name: 'Dashboard',
        path: '/dashboard',
        component: <Dashboard />,
        icon: HomeIcon
    },
    {
        name: 'Customers',
        path: '/customers',
        component: <Customers />,
        icon: UsersIcon
    },
    {
        name: 'Categories',
        path: '/categories',
        component: <Categories />,
        icon: CubeTransparentIcon
    },
    {
        name: 'Styles',
        path: '/styles',
        component: <Styles />,
        icon: PaintBrushIcon
    },
    {
        name: 'Types',
        path: '/types',
        component: <Types />,
        icon: TagIcon
    },
    {
        name: 'Icons',
        path: '/icons',
        component: <Resources resource={'icons'} />,
        icon: RocketLaunchIcon
    },
    {
        name: 'Animations',
        path: '/animations',
        component: <Resources resource={'animations'} />,
        icon: SwatchIcon
    },
    {
        name: '3Ds',
        path: '/threeds',
        component: <Resources resource={'threeds'} />,
        icon: CubeIcon
    },
    {
        name: 'Notifications',
        path: '/notifications',
        component: <Notifications />,
        icon: BellIcon
    },
    {
        name: 'Marketing Emails',
        path: '/marketing-emails',
        component: <MarketingEmails />,
        icon: EnvelopeIcon
    },
    {
        name: 'Customer Requests',
        path: '/requests',
        component: <IconRequests />,
        icon: QuestionMarkCircleIcon
    }
];
