import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import {BrowserRouter} from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './redux/store';
import { Provider } from 'react-redux'
import {AuthProvider} from "./contexts/AuthContext";
import { ToastProvider } from "./contexts/ToastContext";
const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient({
    defaultOptions: {}
});
root.render(
  <>
      <BrowserRouter>
          <Provider store={store}>
              <ToastProvider>
                  <QueryClientProvider client={queryClient}>
                      <AuthProvider>
                          <App/>
                      </AuthProvider>
                  </QueryClientProvider>
              </ToastProvider>
          </Provider>
      </BrowserRouter>

  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
