export const Table = (props) => {
    const { columns, children } = props;
    return (
        <table className="min-w-full divide-y divide-gray-300">
            <thead>
            <tr>
                {
                    columns.map((column, index) => {
                        return (
                            <th key={index} scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-2">
                                {
                                    column
                                }
                            </th>
                        )
                    })
                }
            </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
                {
                    children
                }
            </tbody>
        </table>
)
}
