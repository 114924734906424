import {RocketLaunchIcon, SwatchIcon, CubeIcon} from "@heroicons/react/24/outline";
import {styles} from "../utils/styles";

const list = [
    {
        id: 1,
        name: 'Icons',
        icon: <RocketLaunchIcon />,
        styles: styles.icons,
    },
    {
        id: 2,
        name: 'Animations',
        icon: <SwatchIcon />,
        styles: styles.animations,
    },
    {
        id: 3,
        name: '3Ds',
        icon: <CubeIcon />,
        styles: styles.threeds
    },
]
export const Styles = () => {
    return (
        <ul className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
            {list.map((item) => (
                <li key={item.id} className="overflow-hidden rounded-xl border border-gray-200">
                    <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
                        <div className="w-9">
                            {item.icon}
                        </div>
                        <div className="text-sm font-medium leading-6 text-gray-900">{item.name}</div>
                    </div>
                    <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                            <tr>
                                <th scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                    ID
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    NAME
                                </th>
                            </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                            {item.styles.filter(style => style.id !== undefined).map((style, index) => (
                                <tr key={index}>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                        {style.id}
                                    </td>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                        {style.name}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </dl>
                </li>
            ))}
        </ul>
    )
}
