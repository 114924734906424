import React, {useEffect, useState} from "react";
import {DocumentIcon, FingerPrintIcon, UsersIcon, WalletIcon} from "@heroicons/react/24/outline";
import {AxiosInstance} from "../services/http";
import {useToast} from "../contexts/ToastContext";
import {SmallLoading} from "./loading";
import {getPlans} from "../utils/plans";

export const Statistics = () => {
    const plans = getPlans();
    const [stats, setStats] = useState({
        all_users_count: 0,
        all_subscriptions_count: 0,
        stripe_subscriptions: 0,
        manual_subscriptions: 0,
        stripe_by_plan: [],
        manual_by_plan: [],
    });
    const [loadingStats, setLoadingStats] = useState(false);
    const toast = useToast();
    useEffect(() => {
        setLoadingStats(true);
        AxiosInstance('GET', '/api/v1/stats')
            .then(res => {
                if (res.status === 200) {
                    const result = res.data.resulting_data;
                    const total = getTotalSubscriptions(result.stripe_by_plan, result.manual_by_plan);
                    setStats({
                        all_users_count: result.all_users_count,
                        all_subscriptions_count: total.total,
                        stripe_subscriptions: total.stripes_total,
                        manual_subscriptions: total.manuals_total,
                        stripe_by_plan: result.stripe_by_plan,
                        manual_by_plan: result.manual_by_plan,
                    })
                } else {
                    toast('error', 'Error!', 'Error getting stats!');
                }
                setLoadingStats(false);
            })
    }, [toast]);

    const getByPriceCount = (price_id, list) => {
        const item = list.filter((price) => price.price_id === price_id)[0];
        if (item) {
            return item.total;
        }
        return 0;
    }

    const getPlanMark = (name) => {
        const arr = name.split(' - ');
        return  arr.map(word => {
            return word[0];
        });
    }

    const getTotalSubscriptions = (stripes, manuals) => {
        let total = 0;
        let manuals_total = 0;
        let stripes_total = 0;
        manuals.forEach(manual => {
            manuals_total += manual.total;
        })
        stripes.forEach(stripe => {
            stripes_total += stripe.total;
        })
        total = manuals_total + stripes_total;
        return {
            total,
            manuals_total,
            stripes_total
        }
    }

    return (
        <React.Fragment>
            <div className="mb-5">
                <h3 className="text-base font-semibold leading-6 text-gray-900">Users & Subscriptions</h3>

                <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
                    <div
                        className="relative overflow-hidden rounded-lg bg-white px-4 pb-0 pt-5 shadow-md sm:px-6 sm:pt-6"
                    >
                        <dt>
                            <div className="absolute rounded-md bg-indigo-500 p-3">
                                <UsersIcon className="h-6 w-6 text-white" aria-hidden="true"/>
                            </div>
                            <p className="ml-16 truncate text-sm font-medium text-gray-600">Total Users</p>
                        </dt>
                        <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                            <div className="text-2xl font-semibold text-gray-900">{loadingStats ?
                                <SmallLoading/> : Intl.NumberFormat().format(stats.all_users_count)}</div>
                        </dd>
                    </div>
                    <div
                        className="relative overflow-hidden rounded-lg bg-white px-4 pb-0 pt-5 shadow-md sm:px-6 sm:pt-6"
                    >
                        <dt>
                            <div className="absolute rounded-md bg-indigo-500 p-3">
                                <WalletIcon className="h-6 w-6 text-white" aria-hidden="true"/>
                            </div>
                            <p className="ml-16 truncate text-sm font-medium text-gray-600">Total Subscriptions</p>
                        </dt>
                        <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                            <div className="text-2xl font-semibold text-gray-900">{loadingStats ?
                                <SmallLoading/> : Intl.NumberFormat().format(stats.all_subscriptions_count)}</div>
                        </dd>
                    </div>
                    <div
                        className="relative overflow-hidden rounded-lg bg-white px-4 pb-0 pt-5 shadow-md sm:px-6 sm:pt-6"
                    >
                        <dt>
                            <div className="absolute rounded-md bg-indigo-500 p-3">
                                <DocumentIcon className="h-6 w-6 text-white" aria-hidden="true"/>
                            </div>
                            <p className="ml-16 truncate text-sm font-medium text-gray-600">Stripe Subscriptions</p>
                        </dt>
                        <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                            <div className="text-2xl font-semibold text-gray-900">{loadingStats ?
                                <SmallLoading/> : Intl.NumberFormat().format(stats.stripe_subscriptions)}</div>
                        </dd>
                    </div>
                    <div
                        className="relative overflow-hidden rounded-lg bg-white px-4 pb-0 pt-5 shadow-md sm:px-6 sm:pt-6"
                    >
                        <dt>
                            <div className="absolute rounded-md bg-indigo-500 p-3">
                                <FingerPrintIcon className="h-6 w-6 text-white" aria-hidden="true"/>
                            </div>
                            <p className="ml-16 truncate text-sm font-medium text-gray-600">Manual Subscriptions</p>
                        </dt>
                        <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                            <div className="text-2xl font-semibold text-gray-900">{loadingStats ?
                                <SmallLoading/> : Intl.NumberFormat().format(stats.manual_subscriptions)}</div>
                        </dd>
                    </div>
                </dl>
            </div>
            <div className="mb-5">
                <h3 className="text-base font-semibold leading-6 text-gray-900">Manual Active Subscriptions</h3>

                <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
                    {
                        plans.filter((plan) => plan.id !== 'all').map((plan, index) => {
                            return (
                                <div
                                    key={index} className="relative overflow-hidden rounded-lg bg-white px-4 pb-0 pt-5 shadow-md sm:px-6 sm:pt-6"
                                >
                                    <dt>
                                        <div className="absolute rounded-md bg-indigo-500 p-3">
                                            <span className="text-white font-bold">{getPlanMark(plan.name)}</span>
                                        </div>
                                        <p className="ml-16 truncate text-sm font-medium text-gray-600">{ plan.name }</p>
                                    </dt>
                                    <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                                        <div className="text-2xl font-semibold text-gray-900">{loadingStats ?
                                            <SmallLoading/> : Intl.NumberFormat().format(getByPriceCount(plan.id, stats.manual_by_plan))}</div>
                                    </dd>
                                </div>
                            )
                        })
                    }
                </dl>
            </div>
            <div className="mb-5">
                <h3 className="text-base font-semibold leading-6 text-gray-900">Stripe Active Subscriptions</h3>

                <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
                    {
                        plans.filter((plan) => plan.id !== 'all').map((plan, index) => {
                            return (
                                <div
                                    key={index} className="relative overflow-hidden rounded-lg bg-white px-4 pb-0 pt-5 shadow-md sm:px-6 sm:pt-6"
                                >
                                    <dt>
                                        <div className="absolute rounded-md bg-indigo-500 p-3">
                                            <span className="text-white font-bold">{getPlanMark(plan.name)}</span>
                                        </div>
                                        <p className="ml-16 truncate text-sm font-medium text-gray-600">{ plan.name }</p>
                                    </dt>
                                    <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                                        <div className="text-2xl font-semibold text-gray-900">{loadingStats ?
                                            <SmallLoading/> : Intl.NumberFormat().format(getByPriceCount(plan.id, stats.stripe_by_plan))}</div>
                                    </dd>
                                </div>
                            )
                        })
                    }
                </dl>
            </div>
        </React.Fragment>
    )
}
