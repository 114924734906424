export const getCategories = () => {
    let list = [{
        id: undefined,
        name: 'Select Category'
    }];
    const categories = JSON.parse(localStorage.getItem('categories_list'));
    return [...list, ...categories];
}

export const getCategoryByID = (id) => {
    const categories = getCategories();
    return categories.filter(category => category.id === id)[0];
}

export const getCategoryByIDInList = (id, categories) => {
    return categories.filter(category => category.id === id)[0];
}
