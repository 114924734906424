import { createContext, useState, useEffect, useContext} from "react";
import {AxiosInstance} from "../services/http";
import {AxiosError} from "axios";
const AuthContext = createContext({ user: undefined});
export function useAuth() {
    return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    const getMe = () => {
        return AxiosInstance('GET', '/api/v1/auth/me')
            .then(res => {
                if (res) {
                    if (res instanceof AxiosError) {
                        if (res.status === 401) {
                            localStorage.removeItem('auth_token');
                            localStorage.removeItem('user');
                            if (window.location.pathname !== '/login') {
                                window.location.replace('/login');
                            }
                        }
                    }
                    setUser(res.data.resulting_data.user);
                    localStorage.setItem('user', JSON.stringify(res.data.resulting_data.user));
                    return res;
                }
        })
    }

    useEffect(() => {
        const saved_user = localStorage.getItem('user');
        if (saved_user){
            setUser(JSON.parse(saved_user));
        }else {
            getMe();
        }
    }, []);

    return (
        <AuthContext.Provider value={{user: user}}>
            {children}
        </AuthContext.Provider>
    );

};
