import {useParams} from "react-router-dom";
import {AxiosInstance} from "../services/http";
import {useEffect, useState} from "react";
import {useToast} from "../contexts/ToastContext";
import {Loading} from "../components/loading";
import {NotFound} from "./not.found";
import {SelectBox} from "../components/select.box";
import {getStatusByName, statuses} from "../utils/icon.requests";

export const IconRequest = (props) => {
    const {request_id} = useParams();
    const toast = useToast();
    const [fetching, setFetching] = useState(true);
    const [request, setRequest] = useState(undefined);
    const [isUpdatingRequest, setIsUpdatingRequest] = useState(false);
    const [isDeletingRequest, setIsDeletingRequest] = useState(false);
    const getRequest = (customer_id) => {
        AxiosInstance('GET', '/api/v1/icon-requests/' + request_id)
            .then(res => {
                if (res.status === 200){
                    const response = res.data.resulting_data;
                    setRequest({
                        ...response,
                        status: getStatusByName(response.status)
                    });
                }else {
                    setRequest(null);
                    toast('error', 'Error!', 'Error fetching icon request!');
                }
                setFetching(false);
            })
    }

    const updateRequest = () => {
        setIsUpdatingRequest(true);
        AxiosInstance('PUT', '/api/v1/icon-requests/' + request_id,{},{
            title: request.title,
            description: request.description,
            status: request.status.id
        })
            .then(res => {
                if (res.status === 200){
                    const response = res.data.resulting_data;
                    const result = {
                        ...response,
                        status: getStatusByName(response.status)
                    };
                    setRequest(result);
                    toast('success', 'Success!', 'Icon Request updated successfully!');
                }else{
                    toast('error', 'Error!', 'Error updating icon request!');
                }
                setIsUpdatingRequest(false);
            });
    }

    const deleteRequest = () => {
        setIsDeletingRequest(true);
        AxiosInstance('DELETE',`/api/v1/icon-requests/${request_id}`)
            .then(res => {
                setIsDeletingRequest(false);
                if (res.status === 200){
                    setRequest(null);
                    toast('success', 'Success!', 'Icon Request deleted successfully!');
                }else{
                    toast('error', 'Error!', 'Error deleting icon request!');
                }
            });
    }

    useEffect(() => {
        getRequest(request_id);
    }, [request_id]);
    return (
        fetching ? <Loading/> : (
            request ? (
                <div className="space-y-10 divide-y divide-gray-900/10">
                    <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-6">
                        <div className="px-4 sm:px-0  md:col-span-1">
                            <h2 className="text-base font-semibold leading-7 text-gray-900">
                                Icon Request Details
                            </h2>
                        </div>

                        <form className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-6">
                            <div className="px-4 py-6 sm:p-8">
                                <div className="grid grid-cols-6 gap-x-6 gap-y-8 sm:grid-cols-12">
                                    <div className="sm:col-span-3">
                                        <div className="flex items-center">
                                            <div className="h-20 w-20 flex-shrink-0">
                                                <img className="h-20 w-20 rounded-full"
                                                     src={request.owner.avatar ?? '/images/default-pfp.jpeg'}
                                                     alt=""/>
                                            </div>
                                            <div className="ml-4">
                                                <div
                                                    className="font-medium text-gray-900">{request.owner.name}</div>
                                                <div className="mt-1 text-gray-500">{request.owner.email}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sm:col-span-3">
                                        <label htmlFor="title"
                                               className="block text-sm font-medium leading-6 text-gray-900">
                                            Title
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="title"
                                                id="title"
                                                value={request.title}
                                                onChange={(e) => setRequest({...request, title: e.target.value})}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>
                                    <div className="sm:col-span-3">
                                        <label htmlFor="status"
                                               className="block text-sm font-medium leading-6 text-gray-900">
                                            Status
                                        </label>
                                        <div className="mt-2">
                                            <SelectBox items={statuses} selected={request.status}
                                                       setSelected={(v) => setRequest({...request, status: v})}/>
                                        </div>
                                    </div>
                                    <div className="sm:col-span-9">
                                        <label htmlFor="first-name"
                                               className="block text-sm font-medium leading-6 text-gray-900">
                                            Description
                                        </label>
                                        <div className="mt-2">
                                            <textarea
                                                rows={4}
                                                name="description"
                                                id="description"
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                value={request.description}
                                                onChange={(e) => setRequest({...request, description: e.target.value})}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="flex items-center justify-end gap-x-6 border-gray-900/10 px-4 py-4 sm:px-8">
                                    <button
                                        disabled={isDeletingRequest}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            deleteRequest();
                                        }}
                                        type="submit"
                                        className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                    >
                                        {isDeletingRequest ? 'Please Wait' : 'Delete'}
                                    </button>
                                    <button
                                        disabled={isUpdatingRequest}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            updateRequest();
                                        }}
                                        type="submit"
                                        className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        {isUpdatingRequest ? 'Please Wait' : 'Update'}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            ) : <NotFound/>
        )
    )
}
