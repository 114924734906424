import {useEffect, useState} from "react";
import {AxiosInstance} from "../services/http";

export const useCategories = () => {
    const [categories, setCategories] = useState([]);
    const [gettingCategories, setGettingCategories] = useState(true);
    useEffect(() => {
        AxiosInstance('GET', '/api/v1/categories', {per_page: 1000},undefined,{}, null)
            .then((res) => {
                setCategories(res.data?.resulting_data?.data);
                setGettingCategories(false);
            })
    }, []);
    return {categories, gettingCategories};
}