import {useParams} from "react-router-dom";
import {AxiosInstance} from "../services/http";
import {useEffect, useState} from "react";
import {useToast} from "../contexts/ToastContext";
import {Loading, SmallLoading} from "../components/loading";
import {NotFound} from "./not.found";
import {CheckCircleIcon} from "@heroicons/react/20/solid";
import {SelectBox} from "../components/select.box";
import {getStyleByID, styles} from "../utils/styles";
import {getTypeByID, types} from "../utils/types";
import {getCategoryByIDInList} from "../utils/categories";
import {getIsFreeByID, is_free_list} from "../utils/resources";
import {XMarkIcon} from "@heroicons/react/24/outline";
import {useCategories} from "../hooks/categories";

export const Resource = (props) => {
    const {resource} = props;
    const {resource_id} = useParams();
    const toast = useToast();
    const [fetching, setFetching] = useState(false);
    const [resourceObject, setResourceObject] = useState(undefined);
    const [resourceIcons, setResourceIcons] = useState('');
    const [newTags, setNewTags] = useState('');
    const [isUpdatingResource, setIsUpdatingResource] = useState(false);
    const [isUpdatingTags, setISUpdatingTags] = useState(false);
    const [isAddingTags, setIsAddingTags] = useState(false);
    const [isDeletingResource, setIsDeletingResource] = useState(false);
    const [isUploadingResourceIcons, setIsUploadingResourceIcons] = useState(false);
    const { categories, gettingCategories} = useCategories();
    useEffect(() => {
        if (resource_id){
            setFetching(true);
            getResource(resource_id);
        }
    }, []);

    const getResource = () => {
        AxiosInstance('GET', `/api/v1/${resource}/` + parseInt(resource_id))
            .then(res => {
                if (res?.status === 200){
                    const response = res?.data?.resulting_data;
                    setResourceObject(response);
                }else {
                    setResourceObject(null);
                    toast('error', 'Error!', 'Error fetching resource!');
                }
                setFetching(false);
            })
    }

    const updateResource = (icon) => {
        setIsUpdatingResource(true);
        const uri = `/api/v1/${resource}/${resource_id}`;
        AxiosInstance('PUT', uri,{},{
            name: resourceObject.name,
            style_id: resourceObject.style_id,
            type_id: resourceObject.type_id,
            category_id: resourceObject.category?.id,
            is_free: resourceObject.is_free,
        })
            .then(res => {
                if (res.status === 200){
                    const response = res.data.resulting_data;
                    if (!resource_id){
                        window.location.replace(`/${resource}/${response.id}`);
                    }
                    setResourceObject(response);
                    toast('success', 'Success!', 'Resource added/updated successfully!');
                }else{
                    toast('error', 'Error!', 'Error adding/updating icon resource!');
                }
                setIsUpdatingResource(false);
            });
    }

    const deleteResource = () => {

        setIsDeletingResource(true);
        AxiosInstance('DELETE',`/api/v1/${resource}/${resource_id}`)
            .then(res => {
                setIsDeletingResource(false);
                if (res.status === 200){
                    const response = res.data.resulting_data;
                    setResourceObject(response);
                    if (response.deletedAt !== null){
                        toast('success', 'Success!', 'Resource deleted successfully!');
                    }else{
                        toast('success', 'Success!', 'Resource restored successfully!');
                    }
                }else{
                    toast('error', 'Error!', 'Error deleting resource!');
                }
            });
    }

    const deleteTag = (tag_id) => {
        setISUpdatingTags(true);
        AxiosInstance('DELETE',`/api/v1/${resource}/${resource_id}/tags/${tag_id}`)
            .then(res => {
                setISUpdatingTags(false);
                if (res.status === 200){
                    const response = res.data.resulting_data;
                    setResourceObject(response);
                    toast('success', 'Success!', 'Resource tag deleted successfully!');
                }else{
                    toast('error', 'Error!', 'Error deleting resource tag!');
                }
            });
    }


    const addTas = () => {
        setIsAddingTags(true);
        AxiosInstance('POST',`/api/v1/${resource}/${resource_id}/tags/${newTags}`)
            .then(res => {
                setIsAddingTags(false);
                if (res.status === 200){
                    const response = res.data.resulting_data;
                    setResourceObject(response);
                    toast('success', 'Success!', 'Resource tags added successfully!');
                }else{
                    toast('error', 'Error!', 'Error adding tags to resource!');
                }
            });
    }

    const uploadResourceIcons = (icon) => {

        setIsUploadingResourceIcons(true);

        const formData = new FormData();

        formData.append(
            "file",
            icon,
            `resource-${resource_id ?? 'new-resource'}-${(Math.random() + 1).toString(36).substring(7)}`
        );

        formData.append('model', resource);

        AxiosInstance('POST',`/api/v1/upload`,{},formData)
            .then(res => {
                setIsUploadingResourceIcons(false);
                if (res.status === 200){
                    const icon = res.data.resulting_data;
                    setResourceIcons(icon);
                    toast('success', 'Success!', 'Resource icon successfully uploaded!');
                }else{
                    toast('error', 'Error!', 'Error uploading resource icon!');
                }
            });
    }

    return (
        fetching ? <Loading/> : (
            resource ? (
                <div className="space-y-10 divide-y divide-gray-900/10">
                    <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-6">
                        <div className="px-4 sm:px-0  md:col-span-1">
                            <h2 className="text-base font-semibold leading-7 text-gray-900">
                                Resource Details
                                {(resourceObject && resourceObject.deletedAt !== null) && <span
                                className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                                    DELETED
                                  </span>}
                            </h2>

                        </div>

                        <form className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-6">
                            <div className="px-4 py-6 sm:p-8">
                                <div className="grid grid-cols-6 gap-x-6 gap-y-8 sm:grid-cols-12">
                                    <div className="sm:col-span-1">
                                        <label htmlFor="title"
                                               className="block text-sm font-medium leading-6 text-gray-900">
                                            Icon {resource_id ? `(id : ${resource_id})` : ''}
                                        </label>
                                        <div className="mt-2">
                                            <img src={resourceObject?.preview_url} alt='preview' width={40}/>
                                        </div>
                                        <input
                                            type="text"
                                            hidden={true}
                                            name="icons"
                                            id="icons"
                                            value={resourceIcons}
                                            onChange={(e) => setResourceIcons(e.target.value)}
                                        />
                                    </div>
                                    <div className="sm:col-span-11">
                                        <label htmlFor="first-name"
                                               className="block text-sm font-medium leading-6 text-gray-900">
                                            Actions
                                        </label>
                                        <div className="mt-2">
                                            {/*<span className="mr-2">*/}
                                            {/*    <label*/}
                                            {/*        htmlFor='icon-upload'*/}
                                            {/*        className="cursor-pointer rounded-md bg-indigo-600 px-3 py-2.5 ml-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">*/}
                                            {/*    {isUploadingResourceIcons ? 'Uploading Icons' : (resource_id ? 'Change Icon' : 'Add Icon')}*/}
                                            {/*    </label>*/}
                                            {/*    <input id="icon-upload" name="file-upload" type="file"*/}
                                            {/*           className="cursor-pointer sr-only" onChange={(e) => {*/}
                                            {/*        uploadResourceIcons(e.target.files);*/}
                                            {/*    }}/>*/}
                                            {/*</span>*/}
                                            {
                                                (resourceObject && resourceObject.deletedAt === null) && <button
                                                    disabled={isDeletingResource}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        deleteResource();
                                                    }}
                                                    type="submit"
                                                    className="mr-2 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                                >
                                                    {isDeletingResource ? 'Please Wait' : 'Delete'}
                                                </button>
                                            }
                                            {
                                                (resourceObject && resourceObject.deletedAt !== null) && <button
                                                    disabled={isDeletingResource}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        deleteResource();
                                                    }}
                                                    type="submit"
                                                    className="mr-2 rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline--600"
                                                >
                                                    {isDeletingResource ? 'Please Wait' : 'Restore'}
                                                </button>
                                            }
                                            <button
                                                disabled={isUpdatingResource}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    updateResource(resourceIcons);
                                                }}
                                                type="submit"
                                                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                            >
                                                {isUpdatingResource ? 'Please Wait' : (resource_id ? 'Update' : 'Add Category')}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="sm:col-span-2">
                                        <label htmlFor="title"
                                               className="block text-sm font-medium leading-6 text-gray-900">
                                            Name
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="name"
                                                id="name"
                                                value={resourceObject?.name}
                                                onChange={(e) => setResourceObject({
                                                    ...resourceObject,
                                                    name: e.target.value
                                                })}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>
                                    <div className="sm:col-span-2">
                                        <SelectBox label="Style" items={styles[resource]}
                                                   selected={getStyleByID(resource, resourceObject?.style_id)}
                                                   setSelected={(v) => {
                                                       setResourceObject({
                                                           ...resourceObject,
                                                           style_id: v.id
                                                       })
                                                   }}/>
                                    </div>
                                    <div className="sm:col-span-2">
                                        <SelectBox label="Type" items={types[resource]}
                                                   selected={getTypeByID(resource, resourceObject?.type_id)}
                                                   setSelected={(v) => {
                                                       setResourceObject({
                                                           ...resourceObject,
                                                           type_id: v.id
                                                       })
                                                   }}/>
                                    </div>
                                    <div className="sm:col-span-2">
                                        <SelectBox label="Category" items={categories} loading={gettingCategories}
                                                   selected={getCategoryByIDInList(resourceObject?.category?.id, categories) ?? {id: undefined, name: 'Select Category'}}
                                                   setSelected={(v) => {
                                                       setResourceObject({
                                                           ...resourceObject,
                                                           category: v
                                                       })
                                                   }}/>
                                    </div>
                                    <div className="sm:col-span-2">
                                        <SelectBox label="Is Free" items={is_free_list}
                                                   selected={getIsFreeByID(resourceObject?.is_free)}
                                                   setSelected={(v) => {
                                                       setResourceObject({
                                                           ...resourceObject,
                                                           is_free: v.id
                                                       })
                                                   }}/>
                                    </div>

                                    <div className="sm:col-span-12">
                                        <label htmlFor="verified-at"
                                               className="block text-sm font-medium leading-6 text-gray-900">
                                            Tags
                                        </label>
                                        {
                                            isUpdatingTags ? <SmallLoading/> :
                                                <div className="whitespace-nowrap px-3 py-5 text-sm text-gray-500 grid grid-cols-5 gap-1">
                                                    {
                                                        resourceObject?.tags.map((tag, index) => {
                                                            return (
                                                                <span
                                                                    key={index}
                                                                    className="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-2.5 rounded dark:bg-gray-700 dark:text-gray-300 flex flex-row justify-between">
                                                                    <span>{tag.title}</span>
                                                                    <XMarkIcon width={20} className="cursor-pointer"
                                                                       onClick={() => {
                                                                           deleteTag(tag.id);
                                                                   }}/>
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            // <div className="md:grid-cols-12">
                                            //     {
                                            //         resourceObject?.tags.map((tag, index) => {
                                            //             return (
                                            //                 <div
                                            //                     key={index}
                                            //                     className="md:col-span-1 bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-2.5 rounded dark:bg-gray-700 dark:text-gray-300">
                                            //                     <span className="select-none">{tag.title}</span>
                                            //                     <XMarkIcon width={20} className="cursor-pointer"
                                            //                                onClick={() => {
                                            //                                    deleteTag(tag.id);
                                            //                                }}/>
                                            //                 </div>
                                            //             )
                                            //         })
                                            //     }
                                            // </div>
                                        }
                                    </div>
                                    <div className="sm:col-span-4">
                                        <label htmlFor="title"
                                               className="block text-sm font-medium leading-6 text-gray-900">
                                            Add Tags
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="new_tags"
                                                id="new_tags"
                                                value={newTags}
                                                onChange={(e) => setNewTags(e.target.value)}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            />
                                            <p className="mt-2 text-sm text-gray-500" id="email-description">
                                                Comma separated without spaces. Example: tag1,tag2,tag3
                                            </p>
                                        </div>
                                    </div>
                                    <div className="sm:col-span-2 pt-8">
                                        <button
                                            disabled={isAddingTags}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                addTas();
                                            }}
                                            type="submit"
                                            className="mr-2 rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline--600"
                                        >
                                            {isAddingTags ? 'Please Wait' : 'Add Tags'}
                                        </button>
                                    </div>

                                    <div className="sm:col-span-6">
                                        {
                                            resourceIcons &&
                                            <div className="rounded bg-green-50 p-4">
                                                <div className="flex">
                                                    <div className="flex-shrink-0">
                                                        <CheckCircleIcon className="h-5 w-5 text-green-400"
                                                                         aria-hidden="true"/>
                                                    </div>
                                                    <div className="ml-3">
                                                        <h3 className="text-sm font-medium text-green-800">Icons
                                                            Uploaded!</h3>
                                                        <div className="mt-2 text-sm text-green-700">
                                                            <p>Resource icons uploaded. Just click on Update/Add
                                                                Resource button to complete process!</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            ) : <NotFound/>
        )
    )
}
