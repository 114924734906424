import Datepicker from "react-tailwindcss-datepicker";
import {SelectBox} from "./select.box";
import {getPlanByID, getPlans} from "../utils/plans";
import {useState} from "react";
import {AxiosInstance} from "../services/http";
import { useToast } from "../contexts/ToastContext";

export const ManualSubscription = (props) => {
    const { subscription, user_id, re_fetch_customer } = props;
    const [selectedPlan, setSelectedPlan] = useState(subscription ? getPlanByID(subscription.price_id) : getPlanByID('all'));
    const [loading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [payload, setPayload] = useState({
        manual_subscription_id: subscription ? subscription.id : undefined,
        quantity: subscription ? subscription.quantity : 1,
        price_id: subscription ? subscription.price_id : 'all',
        tiers_mode: selectedPlan.tiers_mode,
        status: "active",
        current_period_start: subscription ? {startDate: subscription.current_period_start.split(' ')[0], endDate: subscription.current_period_start.split(' ')[0]} : {startDate: null, endDate: null},
        current_period_end: subscription ? {startDate: subscription.current_period_end.split(' ')[0], endDate: subscription.current_period_end.split(' ')[0]} : {startDate: null, endDate: null},
    });
    const toast = useToast();
    const plans = getPlans();
    const addSubscriptionToUser = () => {
        setLoading(true);
        AxiosInstance('POST',`/api/v1/customers/${user_id}/subscription`,{},{
            ...payload,
            current_period_start: payload.current_period_start.startDate + ' 00:00:00',
            current_period_end: payload.current_period_end.startDate + ' 00:00:00'
        })
        .then(r => {
            setLoading(false);
            if (r.status === 200){
                toast('success', 'Success!', 'Subscription created/updated for customer!');
                re_fetch_customer()
                setPayload({
                    manual_subscription_id: subscription ? subscription.id : undefined,
                    quantity: subscription ? subscription.quantity : 1,
                    price_id: subscription ? subscription.price_id : 'all',
                    tiers_mode: selectedPlan.tiers_mode,
                    status: "active",
                    current_period_start: subscription ? {startDate: subscription.current_period_start.split(' ')[0], endDate: subscription.current_period_start.split(' ')[0]} : {startDate: null, endDate: null},
                    current_period_end: subscription ? {startDate: subscription.current_period_end.split(' ')[0], endDate: subscription.current_period_end.split(' ')[0]} : {startDate: null, endDate: null},
                })
            }else {
                toast('error', 'Error!', 'Error adding new subscription!!');
            }
        })
    }

    const deleteSubscription = (subscription_id) => {
        setDeleteLoading(true);
        AxiosInstance('DELETE',`/api/v1/customers/${user_id}/subscription/${subscription_id}`)
            .then(r => {
                setDeleteLoading(false);
                if (r.status === 200){
                    toast('success', 'Success!', 'Subscription deleted!');
                    re_fetch_customer()
                }else {
                    toast('error', 'Error!', 'Error deleting subscription!');
                }
            })
    }
    return (
        <form
            className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-full mt-1">
            <div className="px-4 py-6 sm:p-8">
                <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-12">
                    <div className="sm:col-span-3">
                        <label htmlFor="street-address"
                               className="block text-sm font-medium leading-6 text-gray-900">
                            Plan
                        </label>
                        <SelectBox items={plans} selected={selectedPlan} setSelected={(v) => {
                            setPayload({...payload, price_id: v.id, tiers_mode: v.tiers_mode});
                            setSelectedPlan(v);
                        }}/>
                    </div>
                    <div className="sm:col-span-3">
                        <label htmlFor="street-address"
                               className="block text-sm font-medium leading-6 text-gray-900">
                            Quantity
                        </label>
                        <div className="mt-2">
                            <input
                                type="number"
                                name="quantity"
                                id="quantity"
                                onChange={(e) => setPayload({...payload, quantity: Number(e.target.value)})}
                                value={payload.quantity}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                    <div className="sm:col-span-3">
                        <label htmlFor="street-address"
                               className="block text-sm font-medium leading-6 text-gray-900">
                            Start Date
                        </label>

                        <div className="mt-2">
                            <Datepicker
                                useRange={false}
                                asSingle={true}
                                primaryColor={"indigo"}
                                value={payload.current_period_start}
                                inputClassName="w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                onChange={(v) => {
                                    setPayload({...payload, current_period_start: v});
                                }}
                            />
                        </div>
                    </div>
                    <div className="sm:col-span-3">
                        <label htmlFor="street-address"
                               className="block text-sm font-medium leading-6 text-gray-900">
                            End Date
                        </label>
                        <div className="mt-2">
                            <Datepicker
                                useRange={false}
                                asSingle={true}
                                primaryColor={"indigo"}
                                value={payload.current_period_end}
                                inputClassName="w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                onChange={(v) => {
                                    setPayload({...payload, current_period_end: v});
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                {
                    subscription && <button
                        onClick={() => deleteSubscription(subscription.id)}
                        type="button"
                        disabled={deleteLoading}
                        className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">
                        {deleteLoading ? 'Please Wait' : 'Delete'}
                    </button>
                }
                <button
                    disabled={loading}
                    onClick={(e) => {
                        e.preventDefault();
                        addSubscriptionToUser()
                    }}
                    type="submit"
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                    {loading ? 'please waite' : (subscription ? 'Update' : 'Add New Subscription')}
                </button>
            </div>
        </form>
    );
}
