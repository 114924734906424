import qs from 'qs';

export const addQueryParams = (values) => {
    const url = new URL(window.location.href);
    Object.entries(values).forEach((value) => {
        url.searchParams.set(value[0], value[1]);
    })
    window.history.pushState({}, '', url.toString());
};

export const getQueryParam = (key) => {
    const url = new URL(window.location.href);
    return url.searchParams.get(key) || '';
};
export const getQueryParams = () => {
    const url = new URL(window.location.href);
    return qs.parse(url.searchParams.toString());
};
