import {useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import Datepicker from "react-tailwindcss-datepicker";
import qs from 'qs';
import {Loading} from "../components/loading";
import {Pagination} from "../components/pagination";
import {Table} from "../components/table";
import {SelectBox} from "../components/select.box";
import {CheckCircleIcon, XCircleIcon} from '@heroicons/react/20/solid'
import {Link} from "react-router-dom";
import {AxiosInstance} from "../services/http";
import {getPlanByID, getPlans} from "../utils/plans";
import {addQueryParams, getQueryParams} from "../utils/url";

export const Customers = () => {
    const [from, setFrom] = useState(1);
    const [to, setTo] = useState(10);
    const [total, setTotal] = useState(10);
    const [customers, setCustomers] = useState([]);
    const queries = getQueryParams();
    const [params, setParams] = useState({
        page: queries.page ?? 1,
        per_page: 10,
        search: queries.search ?? '',
        plan: queries.plan ? getPlanByID(queries.plan) : getPlanByID('all'),
        registered_date: { startDate: queries.registered_date?.startDate ? queries.registered_date?.startDate : null, endDate: queries.registered_date?.endDate ? queries.registered_date?.endDate : null},
        subscription_type: {
            id: queries.subscription_type ?? 'all',
            name: queries.subscription_type ? queries.subscription_type.toUpperCase() : 'ALL'
        }
    });
    useEffect(() => {
        addQueryParams({
            'search': params.search,
            'page': params.page,
            'per_page': params.per_page,
            'plan': params.plan.id,
            'subscription_type': params.subscription_type.id,
            'registered_date[startDate]': params.registered_date.startDate,
            'registered_date[endDate]': params.registered_date.endDate,
        });
    }, [params]);
    const plans = getPlans();
    const [controller, setController] = useState(undefined);
    const per_page = 10;
    const getCustomers = () => {
        return AxiosInstance('GET', '/api/v1/customers', {...params, plan: params.plan.id, subscription_type: params.subscription_type.id},undefined,{}, controller);
    }
    const { isFetching, data} = useQuery({ queryKey: ['customers', params], queryFn: getCustomers });
    useEffect(() => {
        if (data) {
            setCustomers(data.data.resulting_data.data);
            setFrom(data.data.resulting_data.meta.from);
            setTo(data.data.resulting_data.meta.to);
            setTotal(data.data.resulting_data.meta.total);
            setController(new AbortController());
        }
    }, [data]);
    const columns = [
        "ID",
        "User",
        "Provider",
        "Stripe ID",
        "Status",
        "Last Login",
        "Register Date",
        "Subscription",
        "Actions"
    ];
    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <form className="bg-white shadow p-5 mb-5 rounded-md">
                            <div className="space-y-12">
                                <div className="border-gray-900/10">
                                    <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-12">
                                        <div className="sm:col-span-2">
                                            <label htmlFor="first-name"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                Search
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    type="text"
                                                    name="search"
                                                    id="search"
                                                    value={params.search}
                                                    onChange={(e) => {
                                                        if (controller) {
                                                            controller.abort();
                                                            setController(new AbortController());
                                                        }
                                                        setParams({
                                                            ...params,
                                                            page: 1,
                                                            search: e.target.value
                                                        })
                                                    }}
                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                />
                                            </div>
                                        </div>
                                        <div className="sm:col-span-3">
                                            <label htmlFor="first-name"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                Registered Date
                                            </label>
                                            <div className="mt-2">
                                                <Datepicker
                                                    asSingle={false}
                                                    useRange={true}
                                                    primaryColor={"indigo"}
                                                    value={params.registered_date}
                                                    inputClassName="w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    onChange={(v) => {
                                                        setParams({
                                                            ...params,
                                                            page: 1,
                                                            registered_date: {
                                                                startDate: v.startDate,
                                                                endDate: v.endDate
                                                            }
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="sm:col-span-2">
                                            <SelectBox label="Subscription Type" items={[
                                                {
                                                    id: 'all',
                                                    name: 'ALL'
                                                },
                                                {
                                                    id: 'stripe',
                                                    name: 'MANUAL'
                                                },
                                                {
                                                    id: 'manual',
                                                    name: 'STRIPE'
                                                }
                                            ]}
                                                       selected={params.subscription_type}
                                                       setSelected={(v) => {
                                                           setParams({
                                                               ...params,
                                                               page: 1,
                                                               subscription_type: v
                                                           })
                                                       }}/>
                                        </div>

                                        <div className="sm:col-span-2">
                                            <SelectBox label="Subscription" items={plans} selected={params.plan}
                                                       setSelected={(v) => {
                                                           setParams({
                                                               ...params,
                                                               page: 1,
                                                               plan: v
                                                           })
                                                       }}/>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <label htmlFor="first-name"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                Actions
                                            </label>
                                            <div className="mt-2">
                                                <button
                                                    onClick={() => {
                                                        setParams({
                                                            page: 1,
                                                            per_page: 10,
                                                            search: '',
                                                            plan: getPlanByID('all'),
                                                            registered_date: { startDate: null, endDate: null},
                                                            subscription_type: {
                                                                id: 'all',
                                                                name: 'ALL'
                                                            }
                                                        })
                                                    }}
                                                    type="button"
                                                    className="rounded bg-red-600 px-2 py-1.5 mr-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                                >
                                                    clear
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                            <Table columns={columns}>
                                {
                                    isFetching ? <tr>
                                        <td colSpan={9}><Loading/></td>
                                    </tr> : customers.map((person) => (
                                        <tr key={person.id} className="rounded">
                                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{person.id}</td>
                                            <td className="whitespace-nowrap pl-4 pr-3 text-sm sm:pl-0">
                                                <div className="flex items-center">
                                                    <div className="h-11 w-11 flex-shrink-0">
                                                        <img className="h-11 w-11 rounded-full"
                                                             src={person.avatar ?? '/images/default-pfp.jpeg'} alt=""/>
                                                    </div>
                                                    <div className="ml-4">
                                                        <div className="font-medium text-gray-900">{person.name}</div>
                                                        <div className="mt-1 text-gray-500">{person.email}</div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                                <div className="text-gray-900">{person.provider}</div>
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                                <div className="text-gray-900">{person.stripe_id ?? '-'}</div>
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-5 text-sm">
                                                {
                                                    person.verifiedAt ?
                                                        <span
                                                            className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/10 mr-1">
                                                            Verified
                                                    </span>
                                                        : <span
                                                            className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10  mr-1">
                                                            Not Verified
                                                    </span>
                                                }
                                                {
                                                    person.is_deleted ?
                                                        <span
                                                            className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                                                            Deleted
                                                    </span>
                                                        :
                                                        <span
                                                            className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/10">
                                                            Active
                                                    </span>
                                                }
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{person.last_login_at ?? 'never'}</td>
                                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{person.createdAt}</td>
                                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                                {
                                                    (person.manual_subscriptions.length > 0 || person.stripe_subscriptions.length > 0) ?
                                                        (
                                                            <span
                                                                className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/10 mr-1">
                                                            <CheckCircleIcon color='green' className="-ml-0.5 h-5 w-5"
                                                                             aria-hidden="true"/>
                                                                {person.stripe_subscriptions.length > 0 && ' stripe'}
                                                                {person.manual_subscriptions.length > 0 && ' manual'}
                                                        </span>
                                                        )
                                                        :
                                                        <XCircleIcon color='red' className="-ml-0.5 h-5 w-5"
                                                                     aria-hidden="true"/>
                                                }
                                            </td>
                                            <td className="whitespace-nowrap py-5 pl-3 pr-4 text-sm font-medium sm:pr-0">
                                                <Link
                                                    reloadDocument={true}
                                                    to={'/customers/' + person.id}
                                                    type="button"
                                                    className="rounded bg-indigo-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ml-2"
                                                >
                                                    view
                                                </Link>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </Table>
                            <Pagination from={from} to={to} total={total} per_page={per_page} next={() => setParams({
                                ...params, page: params.page + 1
                            })}
                                        prev={() => setParams({
                                            ...params, page: params.page - 1
                                        })}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
