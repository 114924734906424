import {useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {Loading} from "../components/loading";
import {Pagination} from "../components/pagination";
import {Table} from "../components/table";
import {SelectBox} from "../components/select.box";
import {Link} from "react-router-dom";
import {AxiosInstance} from "../services/http";
import {statuses} from "../utils/icon.requests";
export const IconRequests = () => {
    const [from, setFrom] = useState(1);
    const [to, setTo] = useState(10);
    const [total, setTotal] = useState(10);
    const [requests, setRequests] = useState([]);
    const [params, setParams] = useState({
        page: 1,
        per_page: 10,
        search: '',
        status: {id: 'all', name: 'Select Status'}
    });
    const [controller, setController] = useState(undefined);
    const per_page = 10;
    const getRequests = () => {
        return AxiosInstance('GET', '/api/v1/icon-requests', {...params, status: params.status.id},undefined,{}, controller);
    }
    const { isFetching, data} = useQuery({ queryKey: ['requests', params], queryFn: getRequests });
    useEffect(() => {
        if (data) {
            setRequests(data.data.resulting_data.data);
            setFrom(data.data.resulting_data.meta.from);
            setTo(data.data.resulting_data.meta.to);
            setTotal(data.data.resulting_data.meta.total);
            setController(new AbortController());
        }
    }, [data]);
    const columns = [
        "ID",
        "Requester",
        "Request Type",
        "Request Resource",
        "Resource IDs",
        "Status",
        "Title",
        "Description",
        "Actions"
    ];

    const renderStatus = (status) => {
        let color = 'gray';
        switch (status){
            case 'waiting':
                color = 'yellow';
                break;
            case 'accepted':
                color = 'indigo';
                break;
            case 'declined':
                color = 'red';
                break;
            case 'done':
                color = 'green';
                break;
            default:
                color = 'gray';
        }
        return (
            <span
                className={`inline-flex items-center rounded-md bg-${color}-50 px-2 py-1 text-xs font-medium text-${color}-700 ring-1 ring-inset ring-${color}-600/10 mr-1`}>
                {status}
            </span>
        )
    }

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <form className="bg-white shadow p-5 mb-5 rounded-md">
                            <div className="space-y-12">
                                <div className="border-gray-900/10">
                                    <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                        <div className="sm:col-span-1">
                                            <label htmlFor="first-name"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                Search
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    type="text"
                                                    name="search"
                                                    id="search"
                                                    value={params.search}
                                                    onChange={(e) => {
                                                        if (controller) {
                                                            controller.abort();
                                                            setController(new AbortController());
                                                        }
                                                        setParams({
                                                            page: 1,
                                                            search: e.target.value,
                                                            status: params.status,
                                                            per_page: params.per_page
                                                        })
                                                    }}
                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                />
                                            </div>
                                        </div>

                                        <div className="sm:col-span-1">
                                            <SelectBox label="Status" items={statuses} selected={params.status}
                                                       setSelected={(v) => {
                                                           setParams({
                                                               page: 1,
                                                               search: params.search,
                                                               status: v,
                                                               per_page: params.per_page
                                                           })
                                                       }}/>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <label htmlFor="first-name"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                Legend
                                            </label>
                                            <div className="mt-2">
                                                <span
                                                    className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/10 mr-1">
                                                            waiting
                                                    </span>
                                                <span
                                                    className="inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-600/10 mr-1">
                                                            accepted
                                                    </span>
                                                <span
                                                    className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10 mr-1">
                                                            declined
                                                    </span>
                                                <span
                                                    className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/10 mr-1">
                                                            done
                                                    </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                            <Table columns={columns}>
                                {
                                    isFetching ? <tr>
                                        <td colSpan={10}><Loading/></td>
                                    </tr> : requests.map((request) => (
                                        <tr key={request.id} className="rounded">
                                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{request.id}</td>
                                            <td className="whitespace-nowrap pl-4 pr-3 text-sm sm:pl-0">
                                                <div className="flex items-center">
                                                    <div className="h-11 w-11 flex-shrink-0">
                                                        <img className="h-11 w-11 rounded-full"
                                                             src={request.owner.avatar ?? '/images/default-pfp.jpeg'}
                                                             alt=""/>
                                                    </div>
                                                    <div className="ml-4">
                                                        <div
                                                            className="font-medium text-gray-900">{request.owner.name}</div>
                                                        <div className="mt-1 text-gray-500">{request.owner.email}</div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{request.request_type}</td>
                                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{request.resource_type}</td>
                                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{request.resource_id}</td>
                                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{renderStatus(request.status)}</td>
                                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{request.title}</td>
                                            <td className="px-3 py-5 text-sm text-gray-500">{request.description}</td>
                                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                                <Link
                                                    reloadDocument={true}
                                                    to={'/requests/' + request.id}
                                                    type="button"
                                                    className="rounded bg-indigo-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ml-2"
                                                >
                                                    view
                                                </Link>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </Table>
                            <Pagination from={from} to={to} total={total} per_page={per_page} next={() => setParams({
                                ...params, page: params.page + 1
                            })}
                                        prev={() => setParams({
                                            ...params, page: params.page - 1
                                        })}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
