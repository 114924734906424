import React, {useEffect} from "react";
import './App.css';
import {useAuth} from "./contexts/AuthContext";
import PrivateLayout from "./components/private.layout";
import {Outlet, Route, Routes} from "react-router-dom";
import {Dashboard} from "./pages/dashboard";
import Login from "./pages/login";
import {Loading} from "./components/loading";
import {Customers} from "./pages/customers";
import {NotFound} from "./pages/not.found";
import {Customer} from "./pages/customer";
import {IconRequests} from "./pages/icon.requests";
import {IconRequest} from "./pages/icon.request";
import {Categories} from "./pages/categories";
import {Category} from "./pages/category";
import {Profile} from "./pages/profile";
import {ResourcesBulkUpload} from "./pages/resources.bulk.upload";
import {Styles} from "./pages/styles";
import {Types} from "./pages/types";
import {AxiosInstance} from "./services/http";
import {useToast} from "./contexts/ToastContext";
import {ServerError} from "./pages/500";
import {Resources} from "./pages/resources";
import {Resource} from "./pages/resource";
import {AxiosError} from "axios";
import {Notifications} from "./pages/notifications";
import {MarketingEmails} from "./pages/marketing.emails";
import {Notification} from "./pages/notification";
function App() {
    const { user } = useAuth();
    const toast = useToast();
    const renderRoutes = () => {
        if (user) {
            return renderPrivateRoutes();
        }else {
            return renderPublicRoutes();
        }
    };


    useEffect(() => {
        if (!localStorage.getItem('categories_list')){
            AxiosInstance('GET','/api/v1/categories',{per_page: 1000, page: 1})
                .then(res => {
                    if (res) {
                        if (res instanceof AxiosError) {
                            if (res.status === 401) {
                                localStorage.removeItem('auth_token');
                                localStorage.removeItem('user');
                                if (window.location.pathname !== '/login') {
                                    window.location.replace('/login');
                                }
                            }
                        }
                        if (res.status === 200){
                            const response = res.data.resulting_data.data;
                            localStorage.setItem('categories_list',JSON.stringify(response));
                        }else{
                            toast('error', 'Error!', 'Error getting categories list for filter!');
                        }
                    }

                });
        }
    },[])

    const renderPrivateRoutes = () => {
        return (
            <Routes>
                <Route errorElement={<NotFound />} element={
                    <React.Suspense fallback={<Loading />}>
                        <PrivateLayout> <Outlet /> </PrivateLayout>
                    </React.Suspense>}>
                    <Route index element={<Dashboard />} />
                    <Route path={'dashboard'} element={<Dashboard />} />
                    <Route path={'profile'} element={<Profile />} />
                    <Route path={'customers'}>
                        <Route path={''} element={<Customers />} />
                        <Route path={':customer_id'} element={<Customer />} />
                    </Route>
                    <Route path={'/requests'}>
                        <Route path={''} element={<IconRequests />} />
                        <Route path={':request_id'} element={<IconRequest />} />
                    </Route>
                    <Route path={'/categories'}>
                        <Route path={''} element={<Categories />} />
                        <Route path={'new'} element={<Category />} />
                        <Route path={':category_id'} element={<Category />} />
                    </Route>
                    <Route path={'/styles'}>
                        <Route path={''} element={<Styles />} />
                    </Route>
                    <Route path={'/types'}>
                        <Route path={''} element={<Types />} />
                    </Route>
                    <Route path={'/icons'}>
                        <Route path={''} element={<Resources resource={'icons'} />} />
                        <Route path={'bulk'} element={<ResourcesBulkUpload resource={'icons'} />} />
                        <Route path={'new'} element={<Resource resource={'icons'} />} />
                        <Route path={':resource_id'} element={<Resource resource={'icons'} />} />
                    </Route>
                    <Route path={'/animations'}>
                        <Route path={''} element={<Resources resource={'animations'} />} />
                        <Route path={'bulk'} element={<ResourcesBulkUpload resource={'animations'} />} />
                        <Route path={'new'} element={<Resource resource={'animations'} />} />
                        <Route path={':resource_id'} element={<Resource resource={'animations'} />} />
                    </Route>
                    <Route path={'/threeds'}>
                        <Route path={''} element={<Resources resource={'threeds'} />} />
                        <Route path={'bulk'} element={<ResourcesBulkUpload resource={'threeds'} />} />
                        <Route path={'new'} element={<Resource resource={'threeds'} />} />
                        <Route path={':resource_id'} element={<Resource resource={'threeds'} />} />
                    </Route>
                    <Route path={'/notifications'}>
                        <Route path={''} element={<Notifications />} />
                        <Route path={'new'} element={<Notification />} />
                        <Route path={':notification_id'} element={<Notification/>} />
                    </Route>
                    <Route path={'/marketing-emails'}>
                        <Route path={''} element={<MarketingEmails />} />
                    </Route>
                    <Route path={'/error'}>
                        <Route path={''} element={<ServerError />} />
                    </Route>
                    <Route path="*" element={<NotFound />} />
                </Route>
            </Routes>
        )
    }
    const renderPublicRoutes = () => {
        return (
            <Routes>
                <Route errorElement={<NotFound />}>
                    <Route index element={<Login />} />
                    <Route path={'/login'} element={<Login />} />
                    <Route path={'/register'} element={<div>This is register page!</div>} />
                </Route>
            </Routes>
        )
    }
    return renderRoutes();
}

export default App;
