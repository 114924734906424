export const types = {
    icons: [
        {
            "id": undefined,
            "name": "Select Type"
        },
        {
            "id": 1,
            "name": "regular"
        },
        {
            "id": 2,
            "name": "sharp"
        },
        {
            "id": 3,
            "name": "curved"
        }
    ],
    animations: [
        {
            "id": undefined,
            "name": "Select Type"
        },
        {
            "id": 1,
            "name": "regular"
        },
        {
            "id": 2,
            "name": "sharp"
        },
        {
            "id": 3,
            "name": "curved"
        }
    ],
    threeds: [
        {
            "id": undefined,
            "name": "Select Type"
        },
        {
            "id": 1,
            "name": "Pro"
        },
        {
            "id": 2,
            "name": "Lite"
        }
    ]
};

export const getTypeByID = (resource, id) => {
    return types[resource].filter((item) => item.id === id)[0];
}
