import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid'
export default function BreadCrumb(props) {
    const { pages } = props;
    const getLink = (i, pages) => {
        let link = '/';
        pages.forEach((page, index) => {
            if (index <= i){
                link += page + '/';
            }
        });
        return link;
    };
    return (
        <nav className="flex flex-1" aria-label="Breadcrumb">
            {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
            <ol role="list" className="flex items-center space-x-4">
                <li>
                    <div>
                        <a href="/dashboard" className="text-gray-400 hover:text-gray-500">
                            <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                            <span className="sr-only">Home</span>
                        </a>
                    </div>
                </li>
                {pages.map((page, index) => {
                    return (
                        <li key={index}>
                            <div className="flex items-center">
                                <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true"/>
                                <a
                                    href={getLink(index, pages)}
                                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                                    aria-current={page.current ? 'page' : undefined}
                                >
                                    {page.charAt(0).toUpperCase() + page.slice(1)}
                                </a>
                            </div>
                        </li>
                    )
                })}
            </ol>
        </nav>
    )
}
