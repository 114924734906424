

export const StripeSubscription = (props) => {
    const {subscription} = props;
    return (
        <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-6 mt-1">
            <div className="px-4 py-6 sm:p-8">
                <div className="grid grid-cols-6 gap-x-6 gap-y-8 sm:grid-cols-12">
                    <div className="sm:col-span-4">
                        <label htmlFor="first-name"
                               className="block text-sm font-medium leading-6 text-gray-900">
                            Title :
                        </label>
                        <div className="mt-2 font-semibold text-sm">
                            {subscription.plan.title + ' - ' + subscription.plan.plan}
                        </div>
                    </div>
                    <div className="sm:col-span-4">
                        <label htmlFor="first-name"
                               className="block text-sm font-medium leading-6 text-gray-900">
                            From :
                        </label>
                        <div className="mt-2 font-semibold text-sm">
                            {subscription.current_period_start}
                        </div>
                    </div>
                    <div className="sm:col-span-4">
                        <label htmlFor="first-name"
                               className="block text-sm font-medium leading-6 text-gray-900">
                            To :
                        </label>
                        <div className="mt-2 font-semibold text-sm">
                            {subscription.current_period_end}
                        </div>
                    </div>
                    <div className="sm:col-span-4">
                        <label htmlFor="first-name"
                               className="block text-sm font-medium leading-6 text-gray-900">
                            Quantity :
                        </label>
                        <div className="mt-2 font-semibold text-sm">
                            {subscription.quantity}
                        </div>
                    </div>
                    <div className="sm:col-span-4">
                        <label htmlFor="first-name"
                               className="block text-sm font-medium leading-6 text-gray-900">
                            Stripe ID :
                        </label>
                        <div className="mt-2 font-semibold text-sm">
                            {subscription.stripe_id}
                        </div>
                    </div>
                    <div className="sm:col-span-4">
                        <label htmlFor="first-name"
                               className="block text-sm font-medium leading-6 text-gray-900">
                            Customer ID :
                        </label>
                        <div className="mt-2 font-semibold text-sm">
                            {subscription.customer_id}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
