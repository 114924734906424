export const MarketingEmails = () => {
    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    Work in progress!
                </div>
            </div>
        </div>
    )
}
