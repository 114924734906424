'use client'
import React, {createContext, useContext, useState} from 'react';
import {Toast} from "../components/toast";

const ToastContext = createContext({});

export function useToast() {
    return useContext(ToastContext);
}

export const ToastProvider = ({ children }) => {
    const [show, setShow] = useState(false);
    const [data, setData] = useState({ title : '', message: '', type: 'info'});
    const openToast = (type, title, message ) => {
        setData({ title: title, message: message, type: type});
        setShow(true);
    };
    return (
        <ToastContext.Provider value={openToast}>
            <Toast show={show} setShow={setShow} data={data} />
            {children}
        </ToastContext.Provider>
    );
};
