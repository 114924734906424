import {useEffect, useState} from "react";
import {AxiosInstance} from "../services/http";
import {useQuery} from "@tanstack/react-query";
import {addQueryParams, getQueryParams} from "../utils/url";
import {Link} from "react-router-dom";
import {Table} from "../components/table";
import {Loading} from "../components/loading";
import {Pagination} from "../components/pagination";
import {useToast} from "../contexts/ToastContext";

export const Notifications = () => {

    const queries = getQueryParams();
    const toast = useToast();
    const [from, setFrom] = useState(1);
    const [to, setTo] = useState(10);
    const [total, setTotal] = useState(10);
    const [list, setList] = useState([]);
    const [isDeleting, setIsDeleting] = useState(false);
    const [params, setParams] = useState({
        page: queries.page ?? 1,
        per_page: 10,
        search: queries.search ?? '',
    });

    const [controller, setController] = useState(undefined);

    const per_page = 10;

    useEffect(() => {
        addQueryParams({
            'search': params.search,
            'page': params.page,
            'per_page': params.per_page
        });
    }, [params]);

    const getNotifications = () => {
        return AxiosInstance('GET', '/api/v1/notifications', params, isDeleting,undefined,{}, controller);
    }

    const { isFetching, data} = useQuery({ queryKey: ['notifications',params], queryFn: getNotifications });

    useEffect(() => {
        if (data) {
            setList(data.data.resulting_data.data);
            setFrom(data.data.resulting_data.meta.from);
            setTo(data.data.resulting_data.meta.to);
            setTotal(data.data.resulting_data.meta.total);
            setController(new AbortController());
        }
    }, [data]);

    const deleteNotification = (id) => {
        setIsDeleting(true);
        AxiosInstance('DELETE', '/api/v1/notifications/' + id)
            .then(res => {
                if (res.status === 200) {
                    window.location.reload();
                } else {
                    toast('error', 'Error!', 'Error fetching category!');
                }
                setIsDeleting(false);
            })
    }

    const columns = [
        "ID",
        "Type",
        "Title",
        "Data",
        "Action",
        "Action Text",
        "Action Value",
        "Actions"
    ];

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <form className="bg-white shadow p-5 mb-5 rounded-md">
                            <div className="space-y-12">
                                <div className="border-gray-900/10">
                                    <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-12">
                                        <div className="sm:col-span-2">
                                            <label htmlFor="first-name"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                Search
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    type="text"
                                                    name="search"
                                                    id="search"
                                                    value={params.search}
                                                    onChange={(e) => {
                                                        if (controller) {
                                                            controller.abort();
                                                            setController(new AbortController());
                                                        }
                                                        setParams({
                                                            ...params,
                                                            page: 1,
                                                            search: e.target.value
                                                        })
                                                    }}
                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                />
                                            </div>
                                        </div>
                                        <div className="sm:col-span-2 justify-start">
                                            <label htmlFor="bulk-upload"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                Actions
                                            </label>
                                            <div className="mt-3">
                                                <button
                                                    onClick={() => {
                                                        setParams({
                                                            page: 1,
                                                            per_page: 10,
                                                            search: ''
                                                        })
                                                    }}
                                                    type="button"
                                                    className="rounded bg-red-600 px-2 py-1.5 mr-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                                >
                                                    clear
                                                </button>
                                                <Link
                                                    reloadDocument={true}
                                                    to={"/notifications/new"}
                                                    type="button"
                                                    className="rounded bg-indigo-600 px-2 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                >
                                                    New Notification
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                            <Table columns={columns}>
                                {
                                    isFetching ? <tr>
                                        <td colSpan={8}><Loading/></td>
                                    </tr> : list.map((notification) => (
                                        <tr key={notification.id} className="rounded">
                                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{notification.id}</td>
                                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{notification.type}</td>
                                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{notification.title}</td>
                                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500 text-wrap">{notification.data}</td>
                                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{notification.action}</td>
                                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{notification.action_text}</td>
                                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{notification.action_value}</td>
                                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                                <Link
                                                    reloadDocument={true}
                                                    to={"/notifications/" + notification.id}
                                                    type="button"
                                                    className="rounded bg-indigo-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ml-2"
                                                >
                                                    view
                                                </Link>
                                                <button
                                                    disabled={isDeleting}
                                                    onClick={() => deleteNotification(notification.id)}
                                                    type="button"
                                                    className="rounded bg-red-600 px-2 py-1 ml-2 text-xs font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                                >
                                                    { isDeleting ? 'wait' : 'delete' }
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </Table>
                            <Pagination from={from} to={to} total={total} per_page={per_page} next={() => setParams({
                                ...params, page: params.page + 1
                            })}
                                        prev={() => setParams({
                                            ...params, page: params.page - 1
                                        })}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
